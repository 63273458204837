.plan {
  display:block;
  padding: @padding-large-vertical @padding-large-horizontal;
  margin:5px 0;
  & h5 {
    border-bottom: 1px solid @brand-success;
    margin-bottom:4px;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    background-color: lighten(@brand-success, 30%);
    & h5 {
      border-color: lighten(@brand-success, 10%);
    }
  }
  &.actual {
    background-color: lighten(@brand-success, 40%);
    & h5 {
      border-color: lighten(@brand-success, 20%);
    }
  }
  &.expired {
    background-color: @gray-lighter;
    color:lighten(@text-color,20%);
    & h5 {
      color:inherit;
      border-color: darken(@gray-lighter, 10%);
    }
  }
}