h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  color:@text-color;
  width: 100%;
  padding-bottom:8px;
  position: relative;
  &.underline {
    border-bottom: 1px solid @brand-primary;
  }
  &.underline:before,
  &.underline:after,
  &.underline a:before,
  &.underline a:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 80%;
    bottom: -1px;
    left: 20%;
    border-bottom: 1px solid @gray-lighter;
  }

  //&.underline:after {
  //  left: 40%;
  //  border-bottom-color: #c4262e;
  //}
  //&.underline a:before {
  //  left: 60%;
  //  border-bottom-color: #d9709c;
  //}
  //&.underline a:after {
  //  left: 80%;
  //  border-bottom-color: #ffa100;
  //}
  //
  //&.underline:hover {
  //  border-color: #ffffff;
  //}
  //
  //&.underline:hover:before,
  //&.underline:hover:after,
  //&.underline:hover a:before,
  //&.underline:hover a:after {
  //  border-bottom-color: #ffffff;
  //}
  &:first-child {
    margin-top: 0;
  }
}

& a {
  color: @brand-primary;
  &:hover {
    text-decoration: none;
    //    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }
}

u {
  text-decoration: none;
}


.badge-danger {
  background-color: @brand-danger;
}

.badge-warning {
  background-color: @brand-warning;
}

.badge-success {
  background-color: @brand-success;
}

.badge-info {
  background-color: @brand-info;
}

//.list {
//  &.list-unstyled {
//    // FAQ menu left
//    &.faq {
//      & li {
//        position: relative;
//        font-size: @font-size-base;
//        border-bottom: 1px solid @gray-lighter;
//        line-height: 1.3;
//        & a {
//          display: block;
//          padding-left: 20px;
//          font-size: inherit;
//          line-height: inherit;
//          &:before {
//            font-family: FontAwesome;
//            content: "\f059";
//            position: absolute;
//            left: 0px;
//            color: @brand-primary;
//          }
//        }
//        &.active a {
//          font-weight: bold;
//          &:before {
//            font-weight: normal;
//          }
//        }
//      }
//    }
//  }
//}

.table.styled {
  font-size: @font-size-base*0.7;
  background-color: lighten(@brand-primary, 50%);
  border: 1px solid #fff;
  & thead {
    & th {
      background-color: lighten(@brand-primary, 15%);
      border-bottom: 3px solid #fff;
      text-transform: uppercase;
      vertical-align: bottom;
      width: 16.66%;
      color: #fff;
    }
    & th[scope=row] {
      border: 2px solid #fff;
      background-color: @brand-primary;
      color: #fff;
    }
  }
  & tbody {
    & td {
      border: 2px solid #fff;
    }
    & th[scope=row] {
      background-color: @brand-primary;
      color: #fff;
      border: 2px solid #fff;
      border-right: 3px solid #fff;

    }
  }

  //  th[scope=row] { border-right: 2px solid #333; } { border-right: 2px solid #333; }
}

a {
  & .fa-facebook-official {
    color : #49659F;
    &:hover {
      color : lighten(#49659F, 10%);
    }
  }
  & .fa-linkedin-square {
    color : #0177B5;
    &:hover {
      color : lighten(#0177B5, 10%);
    }
  }
  & .fa-twitter-square {
    color : #28A9E0;
    &:hover {
      color : lighten(#28A9E0, 10%);
    }
  }
  & .fa-envelope-square {
    color : @brand-primary;
    &:hover {
      color : lighten(@brand-primary, 10%);
    }
  }
}
