.content {

  strong {
    color:lighten(@text-color,10%);
  }

  & ul:not(.list):not(.navbar-nav):not(.accordion) {
    list-style-type: none;
    padding-left: 0px;
    margin: 10px 0 10px 5px; // 0 0 15px;
    & li {
      margin-left: 0px;
      padding-left: 15px;
      &:before {
        font-size: @font-size-base*0.5;
        content: '\f0c8';
        font-family: 'FontAwesome';
        color:@brand-primary;
        float: left;
        margin: 7px 0 0 -15px;
      }
    }
  }
  & ul:not(.list):not(.navbar-nav):not(.accordion).check {
    & li, li {
      &:before {
        font-size: @font-size-base;
        content: '\f00c';
        //color:@brand-secundary;
        margin: 0px 0 0 -20px;
      }
    }
    &.primary {
      & li {
        &:before {
          color: @brand-primary;
        }
      }
    }
    &.secundary {
      & li {
        &:before {
          color: @brand-secundary;
        }
      }
    }
  }




  // Simple ordered list styling
  & ol:not(.list):not(.navbar-nav):not(.breadcrumb) {
    list-style-type: none;
    list-style-type: decimal !ie; /*IE 7- hack*/

    margin: 30px 0 30px 0;
    margin-left: 2.5em;
    padding: 0;

    counter-reset: li-counter;
    & li {
      position: relative;
      margin-bottom: 20px;
      padding-left: 0.5em;
      min-height: 3em;
      border-left: 2px solid #CCCCCC;
      &:before {
        position: absolute;
        top: 0;
        left: -1.5em;
        width: 0.8em;

        font-size: 2em;
        line-height: 1;
        font-weight: bold;
        text-align: right;
        color: @brand-primary;

        content: counter(li-counter);
        counter-increment: li-counter;
      }
    }
  }

  // Blockquotes
  blockquote {
    padding: (@line-height-computed / 2) @line-height-computed;
    margin: 0 0 @line-height-computed;
    font-size: @font-size-base * 1.15;
    //border-left: 5px solid @brand-primary;
    border: none;
    font-style: italic;
    color: inherit;

    p,
    ul,
    ol {
      &:last-child {
        margin-bottom: 0;
      }
    }

    // Note: Deprecated small and .small as of v3.1.0
    // Context: https://github.com/twbs/bootstrap/issues/11660
    footer,
    small,
    .small {
      display: block;
      font-size: 80%; // back to default font-size
      line-height: @line-height-base;
      color: @blockquote-small-color;

      &:before {
        content: '\2014 \00A0'; // em dash, nbsp
      }
    }
  }
  form.form-horizontal {
    & span.help-block {
      .small();
    }
  }

}

.tags {
  & a {
    display: inline-block;
    margin: 0 0 4px 0;
    & span.label {
      margin: 0;
    }
    &:hover {
      & span.label {
        background-color: lighten(@label-default-bg, 10%);
      }
    }
  }
}

.bordered {
  border-top:1px solid silver;
  border-bottom:1px solid silver;
}