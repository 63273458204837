.footer {
  background-color : @gray-dark;
  color            : @gray-light;
  & h1, h2, h3, h4, h5, h6 {
    color : lighten(@gray-light, 15%);
  }
  & h3 {
    margin-bottom : 0px;
    padding       : 0;
    line-height   : 1;
  }
  & h4 {
    margin-bottom : 0px;
    line-height   : 1;
  }
  & p.small {
    margin  : 0px 0 10px 0;
    padding : 0;
  }
  & a {
    color : lighten(@gray-light, 30%);
    &:hover {
      color : @brand-secundary;
    }
  }
  & ul.list-unstyled {
    & li {
      & a {
        display      : inline-block;
        color        : lighten(@gray-light, 30%);
        padding-left : 0.7em;
        &:hover {
          color : @brand-secundary;
        }
        &:before {
          //position:absolute;
          width          : 0.7em;
          margin-left    : -0.7em;
          font-family    : FontAwesome;
          content        : "\f105";
          display        : inline-block;
          color          : @brand-secundary;
          //padding-right: 3px;
          vertical-align : middle;
        }
      }
    }
  }
  & ul.list-inline {
    display:inline-block;

  }
}