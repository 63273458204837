.fullbackground {
  margin:0;
  background-color: #00A000;
  padding:0;
}

.swiper-container {

  &.swiper-big {
    z-index: 1000;
    height: 400px; // old browsers
    height: 70vh; // overruled by style
    color: white;
    //background-color: lighten(@brand-primary, 30%) !important;
    & .swiper-slide {
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;

      & .caption {
        position: absolute;
        right: 4vw;
        bottom: 4vh;
       // width: 100%;
        padding: 6px 12px;
        text-align: right;

        & .title {
          font-family: "Open Sans", Helvetica, Arial, sans-serif;
          font-weight: 300;
          font-size: 3vw;
          color: #ffffff;
          text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        }
        & .subtitle {
          display:inline-block;
          font-family: "Open Sans", Helvetica, Arial, sans-serif;
          font-weight: 300;
          font-size: 2vw;
          color: @brand-primary;
          //background-color: @brand-secundary;
          //padding:@padding-base-vertical @padding-large-horizontal;
          text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  &.swiper-home {
    width: 100%; // old browsers
    //height: 500px; // overruled by style
    position: relative;
    & .swiper-slide {

      //& a {
      //height: 500px; // overruled by style
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center;
      transition: all 0.3s ease;
      & a.caption {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        padding: 6px 12px;
        background-color: rgba(0, 0, 0, 0.5);
        & .title {
          color: #ffffff;
        }
        & .subtitle {
          .small();
          color: darken(#ffffff, 30%);
        }
        &:hover {
          //& .caption {
            background-color: @brand-danger;
            & .title, & .subtitle {
              color: #ffffff;
            }
          //}
        }
      }
      //}
    }
    & .swiper-home-buttons {
      font-size: 36px;
      text-align: center;
      & a {
        color: #ffffff;
        &:first-child {
          margin-right: 30px;
        }
        &:hover {
          color: @brand-danger;
        }
      }
    }

    &.swiper-inpage {
      width: 100%; // old browsers
      height: 100%; // overruled by style
      //& .swiper-slide {
      font-size: 18px;
      color: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0px 0px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      & img {
        width: 100%;
      }
    }

    //@media (max-width: @screen-sm-max) {
    //  .swiper-slide .title {
    //    //bottom: 20%;
    //    //font-size: 6vh;
    //    left:0px;
    //    right:0px;
    //    //width:100%;
    //    background-color: rgba(255,255,255,0.20);
    //    z-index: 10;
    //    padding-top: 20px;
    //    padding-bottom: 0px;
    //  }
    //  .swiper-pagination {
    //    display:none;
    //  }
    //
    //  .swiper-slide .subtitle {
    //    bottom: 12%;
    //    font-size: 3vh;
    //    font-weight: 400;
    //  }
    //}

  }
}
