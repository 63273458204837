@import "bootstrap/bootstrap";
@import "customfonts";
@import "variables"; // Overrides

//body, label, .checkbox label {
//  font-weight: 400;
//}

body {
  //padding-top: @navbar-height+34;
  //background: url("/img/wallpaper_2.jpg") no-repeat center top fixed;
  //#gradient > .vertical-three-colors(#111; #222; 5%; #333);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  //font-size:14px;
}

html {
}

[data-sr] {
  visibility: hidden;
}

//@import "base_layout";
@import "helpers";
@import "navbar";
@import "header";
@import "content";
@import "plans";
@import "footer";
@import "forms";
@import "type";
@import "homepage";
@import "jasny/jasny-bootstrap";
@import "activities";
@import "portfolio";

//@import "products";
@import "example4/colorbox.css";
@import (less) "swiper.min.css";
@import "swiper";


//@import "carousel";
//@import "helpers";
//@import "common";
//@import "navigation";
//@import "products";
//@import "custom";
//@import "styles-responsive";
//@import "homepage";



//@import (less) "swiper.min.css";
//@import "swiper";

@import url("//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700|Open+Sans:300,400,400italic,600,700,800");
//@import url("//fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:300,400,600,700,800,300italic");


@btn-font-weight: 400;
//@font-family-sans-serif: "Lato", Helvetica, Arial, sans-serif;

@import "/public/fonts/font-awesome/css/font-awesome.min.css";
@import "customfonts";


