.content {
  & .portfolio {

  }
}

.browse {
  position:relative;
  & a.portfolio {
    display: block;
    margin-bottom: @grid-gutter-width;
    & h3 {
      position:absolute;
      left:0px;
      bottom: 30px;
      margin:0;
      padding: @padding-base-vertical @padding-base-horizontal;
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: @brand-primary;
      color:#fff;
      font-size : @font-size-h3*0.8;
    }
    &:hover h3 {
      background-color: @brand-secundary;

    }
  }
}
