
.gap {
  @gap-height : 10px;
  height : @gap-height;
  &.gap-sm {
    height : 5px;
  }
  &.gap-md {
    height : 10px;
  }
  &.gap-lg {
    height : 20px;
  }
  &.gap-xl {
    height : 40px;
  }
}

@padding-h : 5px;
@padding-v : 5px;

.padded {
  padding : @padding-v*4 @padding-h*6;
}

.padded[class*='col-'] {
  padding : @padding-v*4 @padding-h*6;
}

.padded-v {
  padding : @padding-v*4 0;
}

.padded-v[class*='col-'] {
  padding : @padding-v*4 0;
}

.notpadded, .nopadding {
  padding : 0 0;
}

.container.padded {
  padding : 0 0;
}

/* Small devices (tablets, 768px and up) */
@media (min-width : @screen-sm-min) {
  .padded[class*='col-'] {
    padding : @padding-v*4 @padding-h*5;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width : @screen-md-min) {
  .container.padded {
    padding : @padding-v*4 @padding-h*8;
  }

  .padded[class*='col-'] {
    padding : @padding-v*4 @padding-h*4;
  }

}

.tooltip-inner {
  text-align : left;
}

& span.glossary {
  text-decoration : none;
  border-bottom   : 1px dotted;
  //border-color:darken(currentColor,30%);
  &:hover {
    //background-color: lighten(@brand-primary,10%);
    border-bottom : 1px solid;
    cursor        : pointer;
  }
}

.bg {
  //border: none;
  //box-shadow: none;
  background-color        : transparent;
  -webkit-background-size : cover;
  -moz-background-size    : cover;
  -o-background-size      : cover;
  background-size         : cover;
  background-position     : center center;

  //&.bg-default, &.bg-transparent {
  //  background-color: transparent;
  //}

  &.bg-white, &.bg-default, &.bg-transparent {
    background-color : #ffffff;
    //&.gradient {
    //  #gradient > .vertical(@gray-darker; #222; #000);
    //}
  }
  &.bg-lighter {
    background-color : @gray-lighter;
    //&.gradient {
    //  //#gradient > .vertical-three-colors(lighten(@gray-lighter,10%),lighten(@gray-lighter,5%) @gray-lighter);
    //}
  }
  &.bg-light {
    background-color : @gray-light;
    &.gradient {

    }
  }
  &.bg-medium {
    background-color : @gray;
    &.gradient {

    }
  }
  &.bg-dark {
    background-color : @gray-dark;
    &.gradient {

    }
  }
  &.bg-darker {
    background-color : @gray-darker;
    &.gradient {
      //#gradient > .vertical(lighten(@gray-darker,20%);lighten(@gray-darker,5%);5%; @gray-darker);
      #gradient > .vertical-three-colors(lighten(@gray-darker, 20%); lighten(@gray-darker, 5%); 5%; darken(@gray-darker, 10%));

    }
  }
  &.bg-black {
    background-color : darken(@gray-darker, 20%);
    &.gradient {
      #gradient > .vertical-three-colors(lighten(@gray-darker, 20%); lighten(@gray-darker, 5%); 5%; @gray-darker);

    }
  }
  &.bg-primary {
    background-color : @brand-primary;
    color            : #ffffff;
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
    a {
      font-weight : 600;
      color       : lighten(@brand-primary, 40%);
      &:hover {
        color : lighten(@brand-primary, 50%);
      }
    }
  }

  &.bg-secundary {
    background-color : @brand-secundary;
    color            : rgba(255, 255, 255, 0.8);
    & h1, h2, h3, h4, h5, h6 {
      color : inherit;
    }
    a {
      font-weight : 600;
      color       : lighten(@brand-secundary, 40%);
      &:hover {
        color : lighten(@brand-secundary, 50%);
      }
    }
  }
}

.well {
  border:none;
  margin:0px auto;
  & *:first-child {
    margin-top : 0px;
  }
  & *:last-child {
    margin-bottom : 0px;
  }
  & ul:not(.navlist) {
    margin : 0px 0px 10px;
    & li {
      & a {
        display      : block;
        color        : inherit;
        padding-left : 0.8em;
        &.ellipsis {
          width         : 100%;
          overflow      : hidden;
          white-space   : nowrap;
          text-overflow : ellipsis;
        }
        &:hover {
          color : @brand-primary;
        }
        &:before {
          position       : absolute;
          width          : 0.8em;
          margin-left    : -0.8em;
          font-family    : FontAwesome;
          content        : "\f105";
          display        : inline-block;
          color          : @brand-primary;
          //padding-right: 3px;
          vertical-align : middle;
        }
      }
    }
  }
}

