@font-face {
  font-family: 'biorock';
  src: url('../fonts/biorock.eot?91888949');
  src: url('../fonts/biorock.eot?91888949#iefix') format('embedded-opentype'),
  url('../fonts/biorock.woff?91888949') format('woff'),
  url('../fonts/biorock.ttf?91888949') format('truetype'),
  url('../fonts/biorock.svg?91888949#biorock') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'biorock';
    src: url('../font/biorock.svg?91888949#biorock') format('svg');
  }
}
*/

[class^="bio-"]:before, [class*=" bio-"]:before {
  font-family: "biorock";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.bio-biorock-s:before { content: '\e800'; }
.bio-biorock-l:before { content: '\e801'; }
.bio-biorock-e:before { content: '\e802'; }
.bio-biorock-s-o:before { content: '\e803'; }
.bio-biorock-l-o:before { content: '\e804'; }
.bio-biorock-e-o:before { content: '\e805'; }
.bio-biorock-all:before { content: '\e806'; }