
#navigation {
  & nav {
    font-family : "Open Sans Condensed";
    z-index     : 1001;
    /* Extra small devices (phones, less than 768px) */
    &.navbar {
      font-size     : 19px;
      margin-bottom : 0;
      & .navbar-header {
        & button.navbar-toggle {
          position         : absolute;
          padding          : 4px 6px;
          margin-top       : 13px;
          background-color : lighten(@brand-primary, 10%);
          color            : @brand-primary;
          border           : none;
          right            : 30px;
          & i {
            color : rgba(255, 255, 255, 0.5);
          }
          &:hover {
            background-color : #ffffff;
            color            : @brand-primary;
            & i {
              color : @brand-primary;
            }
          }
        }
        & .navbar-brand {
          display     : block;
          height      : 50px;
          text-align  : center;
          overflow    : visible;
          padding-top : 10px;
          margin      : 0 20px 0 20px;
          & img {
            margin : 0;
            height : 50px;
          }
        }
      }
    }
  }
}

/* Small devices (tablets, 768px and up) */
@media (min-width : @screen-sm-min) {
  #navigation {
    & nav {
      &.navbar {
        //& .navbar-header {
        //  & .navbar-brand {
        //    display : none;
        //  }
        //}
        & ul.navbar-nav {
          text-transform : uppercase;
          & li {
            font-weight: 700;
            & a {
              padding-left  : 4px;
              padding-right : 4px;
            }
            & ul.dropdown-menu {
              & li {
                font-family : "Open Sans";
                font-weight: 300;
                text-transform : none;
              }
            }
          }
        }
      }
    }
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width : @screen-md-min) {
  #navigation {
    & nav {
      &.navbar {
        & .navbar-header {
          & .navbar-brand {
            display : none;
          }
        }
        & ul.navbar-nav {
          text-transform : uppercase;
          & li {
            & a {
              padding-left  : 4px;
              padding-right : 4px;
            }
          }
        }
      }
    }
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width : @screen-lg-min) {
  #navigation {
    & nav {
      &.navbar {
        & .navbar-header {
          & .navbar-brand {
            display : none;
          }
        }
        & ul.navbar-nav {
          text-transform : uppercase;
          & li {
            & a {
              padding-left  : 8px;
              padding-right : 8px;
            }
          }
        }
      }
    }
  }
}

#navigation {
  &.affix {
    position   : fixed;
    z-index    : 1003;
    top        : 0;
    width      : 100%;
    box-shadow : 0 2px 3px rgba(0, 0, 0, 0.3);
    & nav {
      &.navbar {
        & .navbar-header {
          & .navbar-brand {
            display : block;
          }
        }
      }
    }
  }
}

/* Jasny menu */
.navmenu {
  font-family : "Open Sans";
}

/* Sidebar menu */

ul.list.list-unstyled.navlist {
  & li {
    border-bottom : 1px solid @gray-lighter;
    &:last-child {
    border-bottom:none;
    }
    & a {
      display     : block;
      color       : @text-color;
      //text-transform: uppercase;
      font-size   : @font-size-base;
      line-height : 1.3;
      padding     : @padding-base-vertical 0;

    }
    &:hover a {
      color : @brand-primary;
    }
    &.active a {
      color       : @brand-primary !important;
      font-weight : bold;
    }
  }
}

#sidebar {
  &.affix {
    //position   : fixed;
    //z-index    : 1003;
    top   : 0;
    right : 0;
    //width      : 100%;
    //box-shadow : 0 2px 3px rgba(0, 0, 0, 0.3);
  }

}