/*
* Home page blocks width calculation
*/
.col-md-3-0 {
  .make-md-column(3.0)
}

.col-md-3-4 {
  .make-md-column(3.4)
}

.col-md-4-0 {
  .make-md-column(4.0)
}

.col-md-4-8 {
  .make-md-column(4.8)
}

.col-md-6-0 {
  .make-md-column(6.0)
}

.col-md-8-0 {
  .make-md-column(8.0)
}

.col-md-12-0 {
  .make-md-column(12.0)
}

.col-md-24-0 {
  .make-md-column(24.0)
}

.homepage {
  & .welcome {
    & div {
      margin-bottom: 15px;
      & .h-border-top {
        border-top: 1px solid @gray-light;
      }
      & .h-border-bottom {
        border-bottom: 1px solid @gray-light;
      }
      & h2 {
        margin-bottom: 15px;
      }
      & img {
        display: block;
        margin-bottom: 15px;
      }
      //& a.btn-default {
      //  background-color: @gray-darker;
      //  #gradient > .vertical(lighten(@gray-darker,20%); darken(@gray-darker,10%));
      //  font-family: "Open Sans", Helvetica, Arial, sans-serif;
      //  font-size:110%;
      //  color:#ffffff;
      //  text-transform: uppercase;
      //  border:none;
      //  border-bottom:1px solid @brand-danger;
      //  transition: all 0.3s;
      //  & small {
      //    color:@gray-light;
      //  }
      //  &:hover {
      //    background-image: none;
      //    .reset-filter();
      //    background-color: @brand-danger !important;
      //    color:#ffffff;
      //    & small {
      //      color:yellow;
      //    }
      //  }
      //}
    }
  }
  //& .articles {
    & .wrap {
      margin-top: 30px;
      margin-bottom: 15px;
      & .h-border-top {
        border-top: 1px solid @gray-lighter;
      }
      & .h-border-bottom {
        border-bottom: 1px solid @gray-lighter;
      }
      & h2 {
        margin-bottom: 15px;
      }
      & img {
        display: block;
      }
      //& a.btn-default {
      //  background-color: @gray-darker;
      //  #gradient > .vertical(lighten(@gray-darker,20%); darken(@gray-darker,10%));
      //  font-family: "Open Sans", Helvetica, Arial, sans-serif;
      //  font-weight:600;
      //  font-size:80%;
      //  color:@gray-light;
      //  text-transform: uppercase;
      //  border:none;
      //  border-bottom:1px solid @brand-danger;
      //  transition: all 0.3s;
      //  &:hover {
      //    background-image: none;
      //    .reset-filter();
      //    background-color: @brand-danger !important;
      //    color:#ffffff;
      //  }
      //}
    }
  //}

  & ul.check {
    margin: 0;
    padding: 0;
    & li {
      margin: 0;
      padding-left: 1.2em;
      list-style-type: none;
      &:before {
        content: '\f00c'; /* FontAwesome char code inside the '' */
        font-family: FontAwesome; /* FontAwesome or whatever */
        display: inline-block;
        color: @brand-danger;
        width: 1.2em; /* same as padding-left set on li */
        margin-left: -1.2em; /* same as padding-left set on li */
      }
    }
  }
}