.container.topheader {
  //padding : 4px 0px;
  #gradient > .vertical(rgba(255, 255, 255, 0.0); rgba(255, 255, 255, 1));

  & div.logo {
    padding : @padding-v*4 @padding-h*8;
  }

  & ul.flags {
    margin : 4px @padding-h*6;
    & li {
      margin  : 0;
      padding : 0 2px;
      & a {
        display : block;
        margin  : 0;
        .box-shadow(0 2px 3px rgba(0, 0, 0, .35));
        .opacity(0.25);
        &:hover {
          .opacity(0.65);
        }
        & img {
          display : block;
          margin  : 0;
          width   : 20px;
        }
      }
      &.active a {
        .opacity(1);
      }
    }
  }
  & > div > div > div {
    margin : 4px @padding-h*6;
  }


}

.header {
  padding: 0;
  // Page header
  // -------------------------

  & h1 {
    background-color : @brand-secundary;
    color            : lighten(@brand-secundary,40%);
    padding          : @padding-large-vertical 0;
    text-align       : center;
    margin           : 0;
    //border-bottom: 1px solid @page-header-border-color;

  }

}


//& .content-breadcrumb ol {
//  //margin-left: @padding-h;
//border-radius: 0;
//  margin:0;
//  &  li:first-child {
//
//  }
//}
